.bl-hero {
  background: rgb(220, 238, 197);
  background: linear-gradient(
    130deg,
    rgba(220, 238, 197, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(182, 230, 227, 1) 100%
  );
}

.bl-hero-bt {
  background: rgb(220, 238, 197);
  background: linear-gradient(
    130deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(182, 230, 227, 1) 100%
  );
}

.bl-container {
  max-width: 1124px;
  @apply px-4 md:px-8 xl:px-0;
}

.bl-section {
  @apply flex flex-col lg:flex-row bl-container space-y-6 lg:space-x-28 items-center py-24 border-b border-b-grey-30;
}

.bl-section-img {
  @apply lg:w-1/2;
  box-shadow: 2px 6px 20px 10px #f5f5f6;
  background: #f5f5f6;
}

.bl-section-desc {
  @apply space-y-4 flex flex-col justify-center lg:w-1/2;
}
